// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,

  // URL_SERVICE: 'http://150.136.51.89:8080/WSRestApiCoopedacBack/APIRest/',
//  URL_SERVICE: 'http://localhost:8080/WSRestApiCoopedacBack/APIRest/', //Local
    // URL_SERVICE: 'http://localhost:8080/WSRestApiFecoomevaBack/APIRest/', // Local Fecoomeva
//  URL_SERVICE: 'https://tscoopedacportalbe.extractoweb.com/WSRestApiCoopedacBack/APIRest/', //Pruebas
   //**URL_SERVICE: 'https://tsportalfecoomevabe.extractoweb.com/WSRestApiFecoomevaBack/APIRest/', //PRUEBAS FECOOMEVA
// URL_SERVICE: 'http://localhost:8080/WSRestApiFecoomevaBack/APIRest/', // Local Fecoomeva
  URL_SERVICE: 'https://webbankingbe.ebsproductodigital.com/WSRestApiFecoomevaBack/APIRest/', //Pruebas



  // Productos del portal web
  CREDITO: '1',
  APORTES: '2',
  AHORRO_A_LA_VISTA: '3',
  RESERVAS: '4',
  AUXILIOS: '5',
  CDAT: '6', //5
  AHORRO_CONTRACTUAL: '7',
  CONSULTA_DE_SERVICIOS: '8',
  CONSULTA_DE_DEVOLUCIONES: '9',

  //url obtener ip
  URL_CONSULTAR_IP: 'https://api.ipify.org/?format=json',
  USER_BACKEND:'UTI5d1pXUmhZekV5TXlvPQ==',
  PASSWORD_BACKEND:'UTI5dmNHVmtZV05mY0c5eWREUnNLZz09'
};

// Productos del portal web PSE
// ng build --output-hashing=all --base-href /CoopedacTesting/
// COMPILAR PORTAL WEB
// ng build --base-href /PortalCoopedacTest/ --output-hashing=all

 //ng build --output-hashing=all --base-href /CoopedacProd/   //pruebas/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
